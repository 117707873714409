

/* Webfont: Lato-Regular */

@font-face {
  font-family: "LatoWeb";
  src: url("./fonts/Lato-Regular.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Lato-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("./fonts/Lato-Regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */

@font-face {
  font-family: "LatoWebSemibold";
  src: url("./fonts/Lato-Semibold.eot");
  /* IE9 Compat Modes */
  src: url("./fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/Lato-Semibold.woff2") format("woff2"),
    /* Modern Browsers */ url("./fonts/Lato-Semibold.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
