@import "fonts";

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "LatoWeb", sans-serif;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  padding: 0;
}

body {
  background-color: #fff;
  font-size: 11px;
  font-weight: 400;
  color: #000000;
  line-height: 13px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "LatoWebSemibold", sans-serif;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
  outline: none;
}

button {
  cursor: pointer;
}

.veegs-popup {
  font-family: "Roboto", sans-serif;
  background: #ffffff;
  position: fixed;
  max-width: 290px;
  height: 465px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.16);
  font-size: 12px;
  right: 0;
  top: 0;
  z-index: 2147483647;
}

.veegs-wrapper {
  background: #ffffff;
  overflow: hidden;

  .header {
    position: -webkit-sticky;
    position: sticky;
    width: 290px;
    top: 0;
    background: #ffffff;
    z-index: 9999;
  }

  .header-bar.flexbox {
    padding: 12px 10px;
  }

  .content {
    background: #f6f6f6;
    border: 1px solid #e7e7e7;
  }

  .content-scroll {
    max-height: 424px;
    height: 424px;
    overflow: scroll;
    overflow-x: hidden;
  }

  .content-scroll::-webkit-scrollbar {
    height: 0;
    width: 3px;
  }

  .content-scroll::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
    height: 100px;
  }

  .content-scroll::-webkit-scrollbar-corner {
    background: #c1c1c1;
  }

  .list {
    margin-bottom: 12.5px;
    background-color: #f6f6f6;
    display: block;
    color: inherit;
    text-decoration: none;
  }

  .list:last-child {
    margin-bottom: 0;
  }

  .list .list-item {
    padding: 12.51px 20px 12.51px 21px;
    background-color: #ffffff;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    display: flex;
  }

  .list .list-item:before,
  .list .list-item:after {
    content: "";
    display: table;
  }

  .flexbox {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .list .list-item {
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    display: flex;
  }

  .list .item-image {
    padding: 0;
    border: 1px solid #eeeeee;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .item-product .item-image {
    width: 92px;
    min-width: 92px;
    min-height: 92.07px;
    height: 92.07px;
    text-align: center;
  }

  .item-product .item-image .item-pro {
    object-position: top center;
    width: 92px;
    min-width: 92px;
    min-height: 92.07px;
    height: 92.07px;
    text-align: center;
  }
  .item-recipe .pro-desc {
    overflow: hidden;
    max-height: 39px;
    min-height: 39px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: -o-ellipsis-lastline;
  }
  .item-recipe .item-by {
    margin-top: 19px;
  }
  
  .list .item-detail {
    margin-left: 12px;
  }

  .list .item-title {
    line-height: 17px;
    font-size: 14px;
    padding: 2px 0;
    margin: 0;
    font-weight: 600;
    font-family: LatoWebSemibold;
  }

  .item-product .item-desc {
    overflow: hidden;
    //max-height: 32px;
    //min-height: 32px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: -o-ellipsis-lastline;
  }

  .list .item-desc {
    margin: 5px 0 0;
    color: #3c3c3c;
  }

  .pro-desc {
    overflow: hidden;
    max-height: 26px;
    min-height: 26px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: -o-ellipsis-lastline;
  }

  .list .list-item:after {
    clear: both;
  }

  .item-price {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    font-family: LatoWebSemibold;
    display: block;
  }

  .item-by {
    margin-top: 10px;
    line-height: 17px;
    display: block;
    color: #7e7e7e;
  }

  .btn-loadmore {
    color: #3c3c3c;
    border: 1px solid #c4c4c4;
    padding: 0.325rem 1.25rem;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12.5px;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    line-height: 22px;
    text-align: center;
    border-radius: 2px;
    transition: all 0.2s ease-in;
  }

  .item-image .item-pro {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }

  .text-center {
    text-align: center;
  }

}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.btn-link {
  border: none;
  outline: none;
  padding: 5px;
}
